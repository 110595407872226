import React, { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components/macro"
import {
  zIndexes,
  fontFamily,
  fontWeight,
  newPalette,
  buildClassNames as cx,
  pxToRem,
  gridBreakPoints,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { ITiebreakerAnswer } from "../../../../../common/common-utils-types"

const PicksStatusWrap = styled.div`
  width: 8.5rem;
  height: 6rem;
  background-color: ${newPalette.darkBlue1};
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  bottom: 1rem;
  z-index: ${zIndexes.tooltip};

  & > .title {
    font-family: ${fontFamily.condensed};
    font-size: 1.5rem;
    font-weight: ${fontWeight.black};
    line-height: 1;
    letter-spacing: 0.02em;
    text-align: center;
    color: ${newPalette.white95};
    display: flex;
    justify-content: center;
  }

  & > .subtitle {
    font-family: ${fontFamily.condensed};
    font-size: 14px;
    font-weight: ${fontWeight.semiBold};
    line-height: 1rem;
    letter-spacing: 1px;
    text-align: center;
    color: ${newPalette.white50};
    display: flex;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    justify-content: center;
  }

  & > .status-info {
    padding-top: 0.5rem;
    border-top: 1px solid ${newPalette.grayProgressBar};
    font-family: ${fontFamily.base};
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    color: ${newPalette.red};
    justify-content: center;
    margin: 0 -1.25rem;
  }

  & > .status-info.is-complete {
    color: ${newPalette.green};
  }

  @media (max-width: ${pxToRem(gridBreakPoints.breakpoints.sm)}) {
    display: none;
  }
`

interface PicksStatusProps {
  maxPicks: number
  picksCount: number
  poolHasTiebreaker: boolean
  tiebreakerAnswers: ITiebreakerAnswer[]
}

const PicksStatus = React.memo(({ maxPicks, picksCount, tiebreakerAnswers, poolHasTiebreaker }: PicksStatusProps) => {
  const container = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const el = document.getElementById("picks-count-container")
    if (el) {
      container.current = el
    }
  }, [])

  const arePicksComplete = picksCount === maxPicks
  let isComplete = false
  let text = "Incomplete"
  if (arePicksComplete) {
    if (poolHasTiebreaker && (tiebreakerAnswers?.length < 1 || !tiebreakerAnswers[0].value)) {
      text = "Tiebreaker Missing"
    } else {
      text = "Complete"
      isComplete = true
    }
  }

  return (
    container.current &&
    createPortal(
      <PicksStatusWrap>
        <span className="title">{`${picksCount} of ${maxPicks}`}</span>
        <span className="subtitle">PICKS MADE</span>
        <div
          className={cx({
            "status-info": true,
            "is-complete": isComplete,
          })}
        >
          {text}
        </div>
      </PicksStatusWrap>,
      container.current,
    )
  )
})

export default PicksStatus
