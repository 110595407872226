import * as React from "react"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import CustomCopy from "../../../components/CustomCopy"
import Link from "../../../components/Link"
import Modal from "../../../components/Modal"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import Analytics from "../../../utils/analytics"
import { getAuthSearch } from "../../../utils/url-utils"
import { IAuthGateView } from "../AuthGateTypings.d"
import FixedBgScreen from "../styles/FixedBgScreen.styles"
import { AuthGateViewCta, AuthGateViewCtas, AuthGateViewHr, AuthGateViewText, OnboardingFillScreen } from "../styles/SplashViews.styles"
import AuthGateViewLogo from "./AuthGateViewLogo"
import { GameGateModalStyles } from "../styles/GameGateModal.styles"

const AuthGateView = ({
  returnUrl,
  masterProductId,
  productAbbrev,
  gameInstanceUid,
  isCbsAppWebview,
  season,
  segment,
  isModal = false,
  textPrompt,
  headerPrompt,
}: IAuthGateView) => {
  const search = getAuthSearch(returnUrl, productAbbrev, masterProductId, isCbsAppWebview)
  const ContainerComponent = isModal ? GameGateModalStyles : OnboardingFillScreen
  return (
    <ToggleNexusTheme isNexusStyles={!!isModal}>
      <ContainerComponent className={`is-auth--true is-modal--${!!isModal}`}>
        <AnalyticScreen feature={`splash opm`} subfeature={`authenticate`} title={`Splash Page - Authenticate`} isModal={isModal} />
        {!isModal && <FixedBgScreen isModal={isModal} />}
        <AuthGateViewLogo as="section" gameInstanceUid={gameInstanceUid} season={season} segment={segment} className="logo" />
        {headerPrompt && (
          <header>
            <strong>{headerPrompt}</strong>
          </header>
        )}
        <AuthGateViewText as={isModal ? "header" : "h3"} className="title">
          {textPrompt || (gameInstanceUid && <CustomCopy season={season} segment={segment as any} code="authGateText" />) || null}
        </AuthGateViewText>
        <AuthGateViewHr className="hr" />
        <AuthGateViewCtas as="section" className="fm">
          <AuthGateViewCta
            as={Link}
            onClick={() => Analytics.trackInteraction("authenticate - login")}
            to={{
              pathname: "/auth/login",
              search,
            }}
          >
            Login
          </AuthGateViewCta>
          <AuthGateViewCta
            as={Link}
            onClick={() => Analytics.trackInteraction("authenticate - sign up")}
            to={{
              pathname: "/auth/signup",
              search,
            }}
          >
            Create Account
          </AuthGateViewCta>
        </AuthGateViewCtas>
      </ContainerComponent>
    </ToggleNexusTheme>
  )
}

interface IAuthModal extends Omit<IAuthGateView, "returnUrl"> {
  isOpen: boolean
  close: any
  returnUrl?: string
  afterClose?: any
}

export class AuthModal extends React.PureComponent<IAuthModal> {
  public static modalKey = "AuthModal"

  public getReturnUrl = () => {
    if (this.props.returnUrl) {
      return this.props.returnUrl
    }
    if (typeof window !== "undefined") {
      return window.location.href
    }
    return "/"
  }

  public render() {
    const { isOpen, close, returnUrl, afterClose, ...rest } = this.props
    return (
      <Modal isOpen={isOpen} onBackgroundClick={close} afterClose={afterClose}>
        <AuthGateView isModal={true} {...rest} returnUrl={this.getReturnUrl()} />
      </Modal>
    )
  }
}
