import React, { useEffect } from "react"
import styled from "styled-components"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import ATTSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ATT"
import NissanFullSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/NissanFull"
import {
  NCAAW_GAME_INSTANCE_UID,
  NCAAB_S16_GAME_INSTANCE_UID,
  NCAAW_MANAGER_GAME_INSTANCE_UID,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { impressionCacheBusterRegex, replaceWithVguid } from "../../../../../common/common-utils-helpers"
import {
  NCAAB_CHALLENGE_IMPRESSION_URL_SRC,
  NCAAB_MANAGER_IMPRESSION_URL_SRC,
  NCAAW_CHALLENGE_IMPRESSION_URL_SRC,
  NCAAW_MANAGER_IMPRESSION_URL_SRC,
} from "../../../../../common/constants"

const BracketPresentedByWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > svg {
    height: 7rem;
    width: auto;
  }

  @media (max-width: ${bracketTheme.breakpoints.swacketBreakpoint}px) {
    & {
      display: none;
    }
  }
`

const BracketPresentedBy = ({
  sponsorName,
  entryId,
  gameInstanceUid,
}: {
  sponsorName: "nissan" | "att"
  entryId?: string
  gameInstanceUid?: string
}) => {
  if (gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID) {
    return null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let impressionUrl = sponsorName === "nissan" ? NCAAB_CHALLENGE_IMPRESSION_URL_SRC : NCAAB_MANAGER_IMPRESSION_URL_SRC
    if (gameInstanceUid === NCAAW_GAME_INSTANCE_UID) {
      impressionUrl = NCAAW_CHALLENGE_IMPRESSION_URL_SRC
    } else if (gameInstanceUid === NCAAW_MANAGER_GAME_INSTANCE_UID) {
      impressionUrl = NCAAW_MANAGER_IMPRESSION_URL_SRC
    }
    const oneByOneImg = new Image()
    oneByOneImg.src = replaceWithVguid(impressionUrl).replace(impressionCacheBusterRegex, Date.now().toString())
    oneByOneImg.height = 1
    oneByOneImg.width = 1
  }, [entryId, sponsorName, gameInstanceUid])

  return (
    <BracketPresentedByWrap>
      {sponsorName === "nissan" && <NissanFullSvg />}
      {sponsorName === "att" && <ATTSvg />}
    </BracketPresentedByWrap>
  )
}

export default BracketPresentedBy
