import * as React from "react"
import { serializePicks } from "../../../../common/common-utils-helpers"
import { ENUM_CHALLENGE, ENUM_MANAGER } from "../../../../common/enums"
import { emptyArray } from "../../../../common/misc-utils"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import { useDeviceType } from "../../../Base/DeviceType"
import { useLocalKey } from "../../../Base/LocalKey"
import LoadingView from "../../../components/LoadingView"
import useControls from "../../../hooks/useControls"
import { buildPollingProps, extractPoolOgProps, extractPoolSettings } from "../../../hooks/usePoolData"
import BidBarrel from "../../../utils/bid-barrel"
import { placeholderEntry, placeholderPeriodPoint } from "../../../utils/data-utils"
import { getLayoutId } from "../../../utils/url-utils"
import { defaultProps } from "../../Layout/components/Layout"
import { IPicksProps } from "./IPicksProps"
import Picks from "./PicksInner"
import useUpsertEntryMutation from "../../../hooks/useUpsertEntryMutation"
import constants from "../../../../common/constants"
import { canUseDom } from "../../../utils/misc-utils"
import { Redirect, useRouteMatch } from "react-router-dom"

// const debugLabel = "Picks";

function PicksWrapper(props: TPoolRouteProps) {
  const { location, history, match, poolData } = props
  const { params } = match
  const { pathname } = location
  const deviceType = useDeviceType()
  const controls = useControls(props)
  const { updateOrder } = controls
  const {
    allCurrentEntries,
    gameInstancesForArea,
    gameInstanceUid,
    poolId,
    isMine,
    entryId,
    hasAutogeneratedName,
    hasManagerRole,
    isManagerModeActive,
    isIosBrowser,
    isAndroidBrowser,
    isCbsAppWebview,
    hasCbsAccount,
    hasFantasyUser,
    centralTeams,
    poolDetail,
    detailedPeriod,
    detailedEntry,
    detailedPeriodIsLoadingOrChanging,
    productSeason,
    poolRoot,
    isInComingSoon,
    isChallengePool,
    segmentForArea,
    entryDetailsIsLoadingOrChanging,
    entryDetailsVariables,
    centralBracketState,
    toggleManagerMode,
  } = poolData

  const { upsertEntryMutation } = useUpsertEntryMutation(true)
  const modalActions = useLocalKey("modal")
  const openModalKey = modalActions.value
  const openModal = modalActions.update
  const mensBracketLobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

  // END HOOKS

  // const requireAuthGate = (gameRequiresAuthGateForPickPage || isChallengeWithLockedGame || isOpenPoolCreate) && !hasCbsAccount;
  const mergedPoolSettings = extractPoolSettings(poolData)
  if (!detailedPeriod || !mergedPoolSettings) {
    return <LoadingView />
  }
  let canPreviewBracket: boolean | null = false
  if (isInComingSoon && poolData.poolDetail) {
    canPreviewBracket = poolData.poolDetail.canViewEarly
    if (canUseDom && !canPreviewBracket) {
      // NOTE LL: Countdown page to go here
      return <Redirect to={`${withoutDomain(poolData.poolDetail.url)}/standings`} />
    }
  }

  const { isLocked } = centralBracketState?.bracketState || {}
  if (!isChallengePool && !isLocked && !isManagerModeActive && !isMine) {
    return <Redirect to={mensBracketLobbyMatch?.url || ""} />
  }
  // All these go to PicksInner component
  const { hasFinaledGames, hasStartedEvent, eventsCacheKey } = buildPollingProps(poolData)
  const picks = detailedEntry?.picks || emptyArray
  const ogProps = extractPoolOgProps(poolData)
  const gameInstanceUids = gameInstancesForArea.map((gi) => gi.uid)
  const challengeGI = gameInstancesForArea.find((gi) => gi.poolType === ENUM_CHALLENGE)
  const managerGI = gameInstancesForArea.find((gi) => gi.poolType === ENUM_MANAGER)
  const entriesInSport = allCurrentEntries.filter((entr) => gameInstanceUids.includes(entr.gameInstanceUid))
  const hasChallengeEntry = !!entriesInSport.find((etr) => etr.gameInstanceUid === (challengeGI && challengeGI.uid))
  const onMount = location.pathname !== poolRoot ? () => history.replace({ ...location, ...{ pathname: poolRoot || "" } }) : () => undefined
  const hasManagerEntry = !!entriesInSport.find((etr) => etr.gameInstanceUid === (managerGI && managerGI.uid))
  const entryOrBlank = detailedEntry || placeholderEntry
  const needsToMakePicks = detailedEntry?.needsToMakePicks ?? true
  const gameType = (segmentForArea && segmentForArea.gameType) || ""
  const periodPoint = entryOrBlank.periodPoint || placeholderPeriodPoint
  const poolRootPathname = (poolDetail && withoutDomain(poolDetail.url)) || undefined
  const layoutId = getLayoutId(params, ogProps.seasonType)
  const isPoolMember = !!detailedEntry
  const layoutProps = Object.assign({}, defaultProps, {
    layoutId,
    isChallengePool,
    isPoolMember,
    hasManagerRole,
    poolRootPathname,
    pathname,
    hasCbsAccount,
    hasFantasyUser,
    isInComingSoon,
    isCbsAppWebview,
    isIosBrowser,
    isAndroidBrowser,
    productAbbrev: (productSeason && productSeason.productAbbrev) || "",
    deviceType,
  })
  const sideAdProps = BidBarrel.getDomValues({
    adLayoutProps: layoutProps,
    adSlot: "mpu_top",
    requestedPos: "sidebar",
  })
  // NOTE qac: need key to rerender if these items change (mainly for tiebreaker support)
  // we dont want entryId since it will go from "" -> "wefaw123=" when they create for the first time (first pick anon)
  const key = `${isMine}-${poolDetail?.id || null}-${detailedPeriod.id}`
  const entryLoading = isMine ? !!detailedEntry && detailedEntry.id !== entryDetailsVariables.entryId : entryDetailsIsLoadingOrChanging
  const showPicksLoading = detailedPeriodIsLoadingOrChanging || entryLoading
  // console.debug(`key: '${key}'`);
  const entryName = entryOrBlank.name
  const tiebreakerAnswers = entryOrBlank.tiebreakerAnswers
  const poolRank = entryOrBlank.poolRank
  const poolPeriodRank = periodPoint.poolRank
  const poolPeriodFantasyPoints = periodPoint.fantasyPoints
  const ytdFantasyPoints = entryOrBlank.fantasyPoints
  const totalPicksCount = entryOrBlank.totalPicksCount
  const hasSeenNativeAppPromo = entryOrBlank.hasSeenNativeAppPromo
  const mutation = upsertEntryMutation
  const needsAuth = !hasCbsAccount
  const needsEntryName = hasAutogeneratedName
  const mode = isManagerModeActive
  const updateMode = toggleManagerMode
  const picksProps: IPicksProps = {
    ...sideAdProps,
    ...mergedPoolSettings,
    ...ogProps,
    key,
    eventsCacheKey,
    hasFinaledGames,
    hasStartedEvent,
    isCbsAppWebview,
    periodId: detailedPeriod.id,
    period: detailedPeriod,
    picks: serializePicks(picks),
    entryId,
    showPicksLoading,
    detailedPeriodIsLoadingOrChanging,
    updateOrder,
    entryName,
    tiebreakerAnswers,
    poolRank,
    poolPeriodRank,
    poolPeriodFantasyPoints,
    ytdFantasyPoints,
    totalPicksCount,
    hasSeenNativeAppPromo,
    isMine,
    mutation,
    poolId,
    isChallengePool,
    hasManagerEntry,
    hasChallengeEntry,
    gameInstanceUid,
    openModalKey,
    openModal,
    needsAuth,
    needsEntryName,
    hasManagerRole,
    mode,
    updateMode,
    centralTeams,
    gameType,
    onMount,
    isIosBrowser,
    isAndroidBrowser,
    deviceType,
    location,
    needsToMakePicks,
    segmentForArea,
    isInComingSoon,
    canPreviewBracket,
    layoutProps,
  }
  return <Picks {...picksProps} />
}

export default PicksWrapper
