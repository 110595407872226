import { IBracketTrackingElements } from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/BracketSkeleton/BracketSkeletonTypes"

export const trackingElements: Array<IBracketTrackingElements> = [
  {
    id: "team_selection",
    actionType: "trackAction",
    actionName: "brackets interaction",
    args: {
      clickText: "team selection",
      moduleAction: "click",
      moduleCampaign: "brackets",
      moduleLocation: "matchup",
      moduleName: "matchup analysis",
    },
  },
  {
    id: "sportsline_subscribe",
    actionType: "trackAction",
    actionName: "brackets interaction",
    args: {
      clickText: "subscribe to unlock pick",
      moduleAction: "click",
      moduleCampaign: "brackets",
      moduleLocation: "sportsline",
      moduleName: "matchup analysis",
    },
  },
  {
    id: "sportsline_subscribe_impression",
    actionType: "trackAction",
    actionName: "brackets interaction",
    args: {
      clickText: "",
      moduleAction: "impression",
      moduleCampaign: "brackets",
      moduleLocation: "sportsline",
      moduleName: "matchup analysis",
    },
  },
  {
    id: "sportsline_unlock_impression",
    actionType: "trackAction",
    actionName: "brackets interaction",
    args: {
      clickText: "subscribe to unlock pick",
      moduleAction: "impression",
      moduleCampaign: "brackets",
      moduleLocation: "sportsline",
      moduleName: "matchup analysis",
    },
  },
  {
    id: "sportsline_unlock_click",
    actionType: "trackAction",
    actionName: "brackets interaction",
    args: {
      clickText: "subscribe to unlock pick",
      moduleAction: "click",
      moduleCampaign: "brackets",
      moduleLocation: "sportsline",
      moduleName: "bracket autofill",
    },
  },
]
