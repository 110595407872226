import React, { FC, useState } from "react"
import styled, { css } from "styled-components"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import useUpsertEntryMutation from "../../../../hooks/useUpsertEntryMutation"
import { RadioDualLineEntryRow } from "../../containers/EntryRow"
import PoolDataContext from "../../../../Contexts/PoolDataContext"

const DEFAULT_TITLE = "Import Bracket"
const ACTION_PROPS = { dense: true }

const px14 = pxToRem(14)
const importModalText = css`
  font-size: ${px14};
  line-height: ${px14};
  letter-spacing: -0.1px;
  color: ${theme.colors.overLight.white20};
  margin-bottom: 0.75rem;
`

const ImportBracketModalRoot = styled.div`
  width: calc(${pxToRem(488)} - ${pxToRem(80)});

  .import-modal__text {
    ${importModalText}
  }

  .import-modal__text--bold {
    ${importModalText}
    font-weight: ${theme.fonts.weights.bold};
  }

  .import-modal__entry-list {
    padding: 1rem;
    box-sizing: border-box;
    max-height: ${pxToRem(169)};
    border: solid 1px ${theme.colors.overLight.white90};
    border-radius: 0.25rem;
    overflow: hidden auto;
  }

  .import-modal__entry-list-item {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .label-content {
      margin-left: 2rem;
      min-width: 0;
      color: orange;
    }
  }
`

interface IImportBracketModal extends IGenericEntryModalProps {
  title?: string
  close: (saved?: boolean) => void
}

const ImportBracketModal: FC<IImportBracketModal> = ({ close, title = DEFAULT_TITLE, ...rest }) => {
  const poolData = React.useContext(PoolDataContext)
  const entryId = poolData?.entryId

  const {
    upsertEntryMutation,
    upsertEntryMutationResult: { loading },
  } = useUpsertEntryMutation()

  const myEntriesForAreaResult = poolData?.myEntriesForArea()
  const currentEntriesForArea = myEntriesForAreaResult?.currentEntriesForArea ?? emptyArray
  const availableEntries = currentEntriesForArea.filter((e) => e.hasMadeAPick && e.id !== entryId) //only consider entries with at least one pick
  const [selectedEntryId, setSelectedEntryId] = useState<string>(() => availableEntries?.[0]?.id ?? "") //mark the first entry as selected initially

  const handleImport = React.useCallback(() => {
    upsertEntryMutation({
      variables: {
        entryId: entryId,
        entryIdToCopy: selectedEntryId,
      },
      refetchQueries: ["EntryDetailsQuery"],
    }).then(() => close(true))
  }, [entryId, selectedEntryId, upsertEntryMutation, close])

  const importEntryItems = availableEntries.map((entry) => (
    <div className="import-modal__entry-list-item" key={entry.id}>
      <RadioDualLineEntryRow
        name={`import-bracket-${entry.id}`}
        entry={entry}
        isChecked={entry.id === selectedEntryId}
        onChange={() => setSelectedEntryId(entry.id)}
      />
    </div>
  ))

  const actions = (
    <>
      <Button variant="secondary" onClick={() => close()} disabled={loading}>
        Cancel
      </Button>
      <Button variant="primary" disabled={!selectedEntryId} onClick={handleImport} withLoading loading={loading}>
        Import
      </Button>
    </>
  )

  return (
    <GenericEntryModal
      title={title}
      afterClose={close}
      onBackgroundClick={noop}
      onEscapeKeydown={noop}
      actions={actions}
      actionProps={ACTION_PROPS}
      {...rest}
    >
      <ImportBracketModalRoot>
        <div className="import-modal__text--bold">Import a bracket from one of your other CBSSports.com pools. </div>
        <div className="import-modal__text">Select a bracket you would like to import:</div>
        <div className="import-modal__entry-list">{importEntryItems}</div>
      </ImportBracketModalRoot>
    </GenericEntryModal>
  )
}

export default ImportBracketModal
