import { fontFamily, fontWeight, palette, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React from "react"
import styled from "styled-components/macro"

interface ToolBarStatProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode
  label: string
  value?: number | null
}

const ToolBarStatWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 0.5rem;
  min-width: 5.25rem;
  height: 3.5rem;
  background: ${palette.white};
  border: 1px solid ${palette.gray90};
  border-radius: 0.25rem;

  & > div.top-section {
    display: flex;
    flex-direction: row;
    padding: 0 0.5rem;
    width: 100%;
    justify-content: center;

    & > .icon-wrap {
      width: 1.25rem;
      height: 1.25rem;
      color: ${palette.lightBlue3};

      & svg {
        width: 100%;
        height: 100%;
      }
    }
    & > .value {
      font-family: ${fontFamily.base};
      font-size: 1.5rem;
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 1;
      letter-spacing: -0.1px;
      color: ${palette.gray20};
    }
    & > .value.with-icon {
      margin-left: 0.375rem;
    }
  }
  & > div.bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.25rem;

    & > .label {
      font-family: ${fontFamily.condensed};
      font-size: 0.75rem;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: 1.25px;
      color: ${palette.gray50};
      text-transform: uppercase;
    }
  }
  & > div.top-section.with-value {
    padding: 0 0.325rem;
  }

  & + & {
    margin-left: 0.5rem;
  }
`

const ToolBarStat = ({ icon, label, value, ...rest }: ToolBarStatProps) => {
  return (
    <ToolBarStatWrap {...rest}>
      <div
        className={cx({
          "top-section": true,
          "with-value": !!value,
        })}
      >
        {icon && <span className="icon-wrap"> {icon}</span>}
        <span
          className={cx({
            value: true,
            "with-icon": !!icon,
          })}
        >
          {value || "—"}
        </span>
      </div>
      <div className="bottom-section">
        <span className="label">{label}</span>
      </div>
    </ToolBarStatWrap>
  )
}
export default ToolBarStat
