import React, { FC } from "react"
import { View, StyleSheet, Text } from "@react-pdf/renderer"
import {
  IRegionTreeProps,
  getHeightForRound,
  SECONDARY_DEFAULT_COLOR,
  DEFAULT_FONT_FAMILY,
  BASE_MATCHUP_HEIGHT,
  BASE_MATCHUP_WIDTH,
} from "./PrintableTypes"
import RegionRound from "./RegionRound"

const styles = StyleSheet.create({
  relativeContainer: {
    position: "relative",
  },
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  regionNameWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  regionName: {
    color: SECONDARY_DEFAULT_COLOR,
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 700,
    fontSize: 14,
  },
})

const RegionTree: FC<IRegionTreeProps> = ({ matchups, pickUtils, mirrored, variant }) => {
  const baseWidth = BASE_MATCHUP_WIDTH[variant]
  const rounds = matchups?.map((roundMatchups, index) => {
    const [baseHeight] = getHeightForRound(index, BASE_MATCHUP_HEIGHT[variant])
    return (
      <RegionRound key={index} pickUtils={pickUtils} matchups={roundMatchups} baseHeight={baseHeight} baseWidth={baseWidth} mirrored={mirrored} />
    )
  })
  const groupName = matchups?.[0]?.[0]?.groupName ?? ""
  return (
    <View style={styles.relativeContainer}>
      <View style={[styles.regionNameWrapper, { justifyContent: mirrored ? "flex-start" : "flex-end" }]}>
        <Text style={styles.regionName}>{groupName}</Text>
      </View>
      <View style={[styles.root, { flexDirection: mirrored ? "row-reverse" : "row" }]}>{rounds}</View>
    </View>
  )
}

export default RegionTree
