import React, { useCallback, useContext, useState } from "react"
import Autofill from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/AutoFill/AutoFill"
import { useLazyQuery, useQuery } from "@apollo/client/react/hooks"
import { emptyArray, emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import {
  AutoPickSourcesQuery,
  AutoPickSourcesQueryVariables,
  AutoPickSourcesQuery_autopickSources_sections,
  AutoPickSourcesQuery_autopickSources_sections_picks,
} from "../../../../../__generated__/AutoPickSourcesQuery"
import { AUTO_PICK_QUERY, AUTO_PICK_SOURCES_QUERY } from "../../../queries"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { AutoPickQuery, AutoPickQueryVariables, AutoPickQuery_autopick } from "../../../../../__generated__/AutoPickQuery"
import Analytics, { trackSharedLib } from "../../../../utils/analytics"
import { trackingElements } from "../../../../utils/bracket-tracking-elts"

interface TPromoOption {
  selectedOption: string
  sponsorName: string
}
export interface AutoPickContainerProps {
  onConfirm?: (selectedOption: string, isExpert: boolean) => Promise<unknown> | void
  onPromoSelect?: (selectedOption: string, sponsorName: string) => Promise<unknown> | void
  onCancel: () => void
  onAutoPickData?: (data: Array<AutoPickQuery_autopick>) => void
}

const AutoPickContainer = ({ onCancel, onPromoSelect, onAutoPickData }: AutoPickContainerProps) => {
  const [picking, setPicking] = useState(false)
  const [promoOption, setPromoOption] = useState<TPromoOption | undefined>(undefined)
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { gameInstanceUid, poolId, detailedEntry: entry, upsertEntryMutation: mutation, currentSportTypes } = poolDataContext || emptyObject

  const { data: autoPickSources, loading: sourcesLoading } = useQuery<AutoPickSourcesQuery, AutoPickSourcesQueryVariables>(AUTO_PICK_SOURCES_QUERY, {
    variables: {
      gameInstanceUid,
      poolId,
    },
    fetchPolicy: "network-only",
  })

  const confirmCallback = useCallback(() => {
    setTimeout(() => {
      if (promoOption && onPromoSelect) {
        onPromoSelect(promoOption.selectedOption || "", promoOption.sponsorName || "")
      }
      setPromoOption(undefined)
    }, 1500)
  }, [onPromoSelect, promoOption])

  const [getPicks] = useLazyQuery<AutoPickQuery, AutoPickQueryVariables>(AUTO_PICK_QUERY, {
    onCompleted: (data) => {
      if (onAutoPickData) {
        onAutoPickData(data.autopick)
      }
      setPicking(false)
      setTimeout(() => {
        onCancel()
      }, 1000)
      confirmCallback()
    },
    onError: () => {
      setPicking(false)
      setTimeout(() => {
        onCancel()
      }, 1000)
      setTimeout(() => {
        setPromoOption(undefined)
      }, 1000)
    },
  })

  const [getRandomPicks] = useLazyQuery<AutoPickQuery, AutoPickQueryVariables>(AUTO_PICK_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (onAutoPickData) {
        onAutoPickData(data.autopick)
      }
      setPicking(false)
      setTimeout(() => {
        onCancel()
      }, 1000)
      confirmCallback()
    },
    onError: () => {
      setPicking(false)
      setTimeout(() => {
        onCancel()
      }, 1000)
      setTimeout(() => {
        setPromoOption(undefined)
      }, 1000)
    },
  })

  const handleAutoFillClick = useCallback(
    async (selectedOption: string) => {
      // setPromoOption(selectedOption)
      Analytics.trackAction("fill bracket", "confirm", `auto-fill - ${selectedOption}`)
      let section: AutoPickSourcesQuery_autopickSources_sections | undefined = undefined
      let pick: AutoPickSourcesQuery_autopickSources_sections_picks | undefined = undefined
      const sections = autoPickSources?.autopickSources?.sections || emptyArray
      for (const sectionItem of sections) {
        const selectedPick = sectionItem.picks.find((x) => x.id === selectedOption)
        if (selectedPick) {
          section = sectionItem
          pick = selectedPick
          if (sectionItem.isSponsor) {
            setPromoOption({
              selectedOption: selectedPick.id || "",
              sponsorName: sectionItem.id,
            })
          }
          break
        }
      }
      if (section) {
        const variables: AutoPickQueryVariables = {
          gameInstanceUid,
          autopickOption: "",
        }

        if (section.isExpert || section.isSportLine) {
          // this are entries.
          variables.autopickOption = pick?.id || ""
        } else {
          // progamatically auto-fill
          variables.autopickOption = pick?.id || ""
        }
        setPicking(true)
        if (pick?.id === "RANDOM" || pick?.id === "HISTORICAL_RANDOM") {
          getRandomPicks({
            variables,
          })
        } else {
          getPicks({
            variables,
          })
        }
      }
    },
    [autoPickSources?.autopickSources?.sections, confirmCallback, currentSportTypes, entry?.id, gameInstanceUid, getPicks, mutation, onCancel],
  )

  return (
    <Autofill
      isLoading={sourcesLoading}
      isLoadingPicks={picking}
      data={autoPickSources?.autopickSources as any}
      handleAutoFillCancelClick={onCancel}
      handleAutoFillClick={handleAutoFillClick}
      trackingElements={trackingElements}
      trackingHandler={trackSharedLib}
    />
  )
}

export default AutoPickContainer
