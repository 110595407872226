import React, { FC } from "react"
import styled from "styled-components"
import { PDFViewer } from "@react-pdf/renderer"
import PrintableBracket, { IPrintableBracketProps } from "./PrintableBracket"

const PrintableBracketViewerRoot = styled.div`
  width: 90vmin;
  height: 75vmin;
`

type IProps = IPrintableBracketProps

const PrintableBracketViewer: FC<IProps> = (props) => {
  return (
    <PrintableBracketViewerRoot>
      <PDFViewer width="100%" height="100%">
        <PrintableBracket {...props} />
      </PDFViewer>
    </PrintableBracketViewerRoot>
  )
}

export default PrintableBracketViewer
