import styled from "styled-components/macro"
import { pxToRem } from "../../../utils/style-utils"

export const OptionsMenu = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 100%;
  max-width: 90rem;
  &:not(:empty) {
    margin-bottom: var(--grid-gap);
  }
  & > * {
    margin-right: 1rem;
  }
  & .members-dropdown {
    width: ${pxToRem(160)};
  }
  & .members-dropdown:empty {
    display: none;
  }

  & .manager-mode-toggle {
    background: #ffffff;
    border: 1px solid #dae0e5;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0.625rem 0 0.625rem 1rem;
  }

  & .manager-mode-toggle.single-bracket-area {
    height: 2.25rem;
  }

  .print-bracket {
    margin-left: auto;
    margin-right: 0;
  }
`
