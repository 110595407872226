import { fontWeight, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React from "react"
import styled from "styled-components/macro"
import { palette, pxToRem } from "../utils/style-utils"

const ToggleLabel = styled.label`
  font-size: ${pxToRem(12)};
  margin-right: ${pxToRem(8)};
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeight.medium};
  color: ${palette.gray20};

  & input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & > span.with-colon-sign::after {
    content: ":";
  }
  & > span.with-margin {
    margin-right: 0.5rem;
  }
`

const ToggleLabelCheckbox = styled.div<{ inputCheckedBackgroundColor: string }>`
  width: ${pxToRem(32)};
  height: ${pxToRem(16)};
  background: ${palette.gray75};
  display: block;
  border-radius: ${pxToRem(16)};
  position: relative;
  margin: 0 ${pxToRem(8)};
  &:after {
    content: "";
    position: absolute;
    top: ${pxToRem(1)};
    left: ${pxToRem(1)};
    width: ${pxToRem(14)};
    height: ${pxToRem(14)};
    background: #fff;
    border-radius: ${pxToRem(14)};
    transition: all 0.3s ease-out;
  }
  input:checked + & {
    background: ${(props) => props.inputCheckedBackgroundColor};
  }
  input:checked + &:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  &:active:after {
    width: ${32 - 14}px;
  }
`

interface IToggle {
  onChange: (newValue: boolean) => void
  checked: boolean
  label: string
  name?: string
  before?: boolean
  inputCheckedBackgroundColor?: string
  isMultipleArea?: boolean
}

const Toggle = ({
  onChange,
  checked,
  label,
  name,
  before = true,
  inputCheckedBackgroundColor = palette.logoOrange,
  isMultipleArea = false,
}: IToggle) => {
  return (
    <ToggleLabel data-cy="manager_mode">
      {before && (
        <span
          className={cx({
            "with-colon-sign": !isMultipleArea,
            "with-margin": isMultipleArea,
          })}
        >
          {label}
        </span>
      )}
      <input
        name={name ? name : label.replace(/\s+/g, "-").toLowerCase()}
        type="checkbox"
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
        value={checked.toString()}
      />
      <ToggleLabelCheckbox inputCheckedBackgroundColor={inputCheckedBackgroundColor} />
      {!before && <span>{label}:</span>}
    </ToggleLabel>
  )
}

export default Toggle
