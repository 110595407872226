import React, { FC } from "react"
import { ISubsectionProps, isTopSubSection } from "./PrintableTypes"
import RegionTree from "./RegionTree"

const TopSubSection: FC<ISubsectionProps> = ({ matchups, pickUtils, mirrored, variant }) => {
  const filteredMatchups = matchups?.map((matchupArr) => matchupArr.filter(isTopSubSection))
  return <RegionTree pickUtils={pickUtils} matchups={filteredMatchups} mirrored={mirrored} variant={variant} />
}

export default TopSubSection
