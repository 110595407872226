import * as React from "react"
import { ENUM_BRACKET } from "../../../../common/enums"
import PickUtils from "../../../../common/pick-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import LoadingView from "../../../components/LoadingView"
import Helmet from "../../../shared/react-helmet-async/Helmet"
import { cancelAnimationFrame, canUseDom } from "../../../utils/misc-utils"
import GameOgMeta from "../../GameOgMeta"
import { AuthModal } from "../../NonPoolPages/components/AuthGateView"
import NativeAppPromoModal from "../components/NativeAppPromoModal"
import { PicksPageLayout, PoolPage } from "../components/PoolPage"
import { IPicksProps } from "./IPicksProps"

import { deserializePicks } from "../../../../common/common-utils-helpers"
import Bracket from "../components/Bracket"
import { filterNulls } from "../../../../common/misc-utils"
import { gatherPoolSettings } from "../../../hooks/usePoolData"

interface IPicksState {
  errorMessage: string | null
}

const emptyObject = {} as never
const emptyArray = [] as never[]

// TODO qac: fix

const fakeDomRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
} as DOMRect | ClientRect

class Picks extends React.PureComponent<IPicksProps, IPicksState> {
  public state = {
    errorMessage: null,
  }

  public entryBarStickyFooterRef = React.createRef<HTMLFormElement>()

  public pickUtils: null | PickUtils = null

  public dims = {
    screenW: 0,
    screenH: 0,
    scrollTop: 0,
    scrollParentTop: 0,
    scrollParentRect: fakeDomRect,
    entryBarRect: fakeDomRect,
    entryBarStickyFooterRect: fakeDomRect,
  }

  public timeoutIds = {
    resize: 0,
  }
  public rafIds = ["processDOM"]
  public elements = {
    scrollContainer: null as null | Element,
    entryBar: null as null | Element,
    entryBarStickyFooter: null as null | Element,
  }

  public getTimeoutId = (key: string) => this.timeoutIds[key]
  public clearTimeout = (key: string) => {
    const id = this.getTimeoutId(key)
    if (id) {
      if (this.rafIds.includes(key)) {
        cancelAnimationFrame(id)
      } else {
        clearTimeout(id)
      }

      this.timeoutIds[key] = 0
    }
  }

  public componentDidMount() {
    const { onMount } = this.props
    // account for root treatment for picks page:
    if (onMount) {
      onMount()
    }
  }

  public isBodyScroll = () => (canUseDom ? this.elements.scrollContainer === document.body : this.props.isIosBrowser && this.props.isCbsAppWebview)

  public recalculateDimensions = () => {
    this.log(`recalculateDimentions`)
    if (canUseDom && this.elements.scrollContainer) {
      const isBodyScroll = this.isBodyScroll()
      const docEl = window.document.documentElement || emptyObject
      this.dims.screenW = docEl.clientWidth || window.innerWidth
      this.dims.screenH = docEl.clientHeight || window.innerHeight
      this.dims.scrollTop = this.getScrollTop()
      this.dims.scrollParentRect = this.elements.scrollContainer.getBoundingClientRect()
      // for body scroll, this is assumed to be 0 (no offset from headers!)
      this.dims.scrollParentTop = isBodyScroll ? 0 : this.dims.scrollParentRect.top
      if (this.elements.entryBar) {
        this.dims.entryBarRect = this.elements.entryBar.getBoundingClientRect()
      }
      const el = this.entryBarStickyFooterRef.current // || this.elements.entryBarStickyFooter
      if (el) {
        this.dims.entryBarStickyFooterRect = el.getBoundingClientRect()
      }
    }
    // console.debug(`recalculateDimensions:`, this.dims, this.elements)
  }

  public getScrollTop = () => (this.isBodyScroll() ? window.scrollY : this.elements.scrollContainer!.scrollTop)

  public onScroll = (_event: Event) => {
    // console.debug(`onscroll:`, event)
    if (this.elements.scrollContainer) {
      const scrollTop = this.getScrollTop()
      if (this.dims.scrollTop !== scrollTop) {
        this.dims.scrollTop = scrollTop
        if (this.elements.entryBar) {
          this.dims.entryBarRect = this.elements.entryBar.getBoundingClientRect()
        }
        const el = this.entryBarStickyFooterRef.current // || this.elements.entryBarStickyFooter
        if (el) {
          this.dims.entryBarStickyFooterRect = el.getBoundingClientRect()
        }
      }
    }
  }
  public onResize = (_event: Event) => {
    // console.debug(`onResize:`, event)
    const resizeTO = this.timeoutIds.resize
    this.log(`onResize: ${resizeTO} ${_event.type}`)
    if (resizeTO) {
      clearTimeout(resizeTO)
      this.timeoutIds.resize = 0
    }
    this.timeoutIds.resize = setTimeout(this.recalculateDimensions, 800)
  }

  public hasUpsellModal() {
    return this.props.gameType === ENUM_BRACKET
  }

  public componentWillUnmount() {
    // console.debug("componentWillUnmount");
    Object.keys(this.timeoutIds).forEach(this.clearTimeout)
    if (this.elements.scrollContainer) {
      const listener = this.elements.scrollContainer === document.body ? window : this.elements.scrollContainer
      listener.removeEventListener("scroll", this.onScroll)
      window.removeEventListener("resize", this.onResize)
      if (this.props.isIosBrowser && this.props.isCbsAppWebview) {
        document.removeEventListener("focusout", this.onResize)
      }
    }
    Object.keys(this.elements).forEach((key) => (this.elements[key] = null))
  }

  public closeModal = () => {
    this.props.openModal(null)
  }

  public log(item: any) {
    if (canUseDom && window.hasOwnProperty("QQ")) {
      //  || !constants.IS_PRODISH
      console.dir(item)
      window["Picks"] = this
    }
  }

  public getMatchups() {
    return this.props.period.matchups || emptyArray
    // const tournamentIds = this.props.tournamentIds || [];
    // // console.log(`getMatchups:`, this.props.tournamentIds)
    // return (this.props.period.matchups || []).filter(
    //   ({tournamentId}) => tournamentIds.includes(tournamentId),
    // );
  }

  public buildPickUtils() {
    const { picks, period, centralTeams, entryId } = this.props
    const dbPicks = deserializePicks(picks)
    const deserializedPicks = dbPicks
    const matchups = this.getMatchups()
    const events = matchups.map((matchup) => matchup.event).filter(filterNulls)
    const overriding = this.inManagerMode()
    this.pickUtils = PickUtils.buildFor(
      deserializedPicks,
      events as any,
      period as any,
      matchups as any,
      centralTeams,
      gatherPoolSettings(this.props),
      {
        overriding,
        extraCacheKey: entryId,
      },
      this.pickUtils,
    )
    const diff = this.pickUtils.getDiffFrom(dbPicks)
    const needsSave = diff.length > 0
    // console.log(`needsSave: ${this.pickUtils.needsSave} -> ${needsSave}`, diff, dbPicks)
    this.pickUtils.needsSave = needsSave
    return this.pickUtils
  }

  public showError(message: string) {
    this.setState({
      errorMessage: message,
    })
  }

  public markNativeAppPromoViewed = () => {
    const { mutation, periodId, gameInstanceUid, poolId, entryId, hasSeenNativeAppPromo } = this.props
    if (hasSeenNativeAppPromo === false) {
      const variables = {
        entryId,
        gameInstanceUid,
        poolId,
        periodId,
        hasSeenNativeAppPromo: true,
      }
      mutation({ variables }).catch((err) => window.SH_ERROR_NOTIFY(err, "native-app-promo"))
    }
  }

  public inManagerMode = () => !!this.props.hasManagerRole && !!this.props.mode

  public render() {
    const {
      period,
      gameInstanceUid,
      entryName,
      mode,
      updateMode,
      poolName,
      openModalKey,
      hasManagerRole,
      deviceType,
      showPicksLoading,
      isChallengePool,
      isIosBrowser,
      isCbsAppWebview,
    } = this.props
    const pickUtils = this.buildPickUtils()

    const season = period.segment.season
    const layoutSharedClassNames = `is-ios--${isIosBrowser} is-multiple-horiz-sections--false`
    this.log("PicksInner")

    let impressionTrackingExtra = {}
    impressionTrackingExtra = isChallengePool
      ? { sponsorName: "nissan logo", nissanLogoImpression: 1 }
      : { sponsorName: "att logo", attLogoImpression: 1 }
    return (
      <PoolPage className={layoutSharedClassNames}>
        {isIosBrowser && (
          <Helmet>
            <body className="disable-ios-tablet-overflowscroll" />
          </Helmet>
        )}
        <AnalyticScreen feature="brackets" subfeature={`make-picks`} title={`Bracket`} deviceType={deviceType} extra={impressionTrackingExtra} />
        {season && (
          <GameOgMeta gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} poolName={poolName} entryName={entryName} />
        )}
        <PicksPageLayout
          handheldUsesSticky={isIosBrowser}
          className={`${layoutSharedClassNames} is-bracket--true`}
          topCenterElement={null}
          leftCol={null}
          rightCol={null}
          bottomCenterElement={null}
        >
          {(showPicksLoading && <LoadingView />) || (
            <Bracket hasManagerRole={hasManagerRole} pickUtils={pickUtils} mode={mode} updateMode={updateMode} gameInstanceUid={gameInstanceUid} />
          )}
        </PicksPageLayout>
        {!isCbsAppWebview && (
          <NativeAppPromoModal
            isOpen={openModalKey === NativeAppPromoModal.modalKey}
            close={this.closeModal}
            afterOpen={this.markNativeAppPromoViewed}
            gameInstanceUid={gameInstanceUid}
            season={season}
            afterClose={() => undefined}
          />
        )}
        <AuthModal
          isOpen={openModalKey === AuthModal.modalKey}
          close={this.closeModal}
          season={season}
          productAbbrev={season.productAbbrev}
          masterProductId={season.masterProductId}
          gameInstanceUid={gameInstanceUid}
          isCbsAppWebview={isCbsAppWebview}
        />
      </PoolPage>
    )
  }
}

export default Picks
