import React, { FC } from "react"
import { View, Canvas, Text, StyleSheet } from "@react-pdf/renderer"
import { displayTeamAbbrev } from "@cbs-sports/sports-shared-client/build/cjs/utils/misc-utils"
import {
  IMatchupProps,
  SELECTION_CORRECT_COLOR,
  SELECTION_INCORRECT_COLOR,
  DEFAULT_COLOR,
  SECONDARY_DEFAULT_COLOR,
  DEFAULT_FONT_FAMILY,
} from "./PrintableTypes"

const styles = StyleSheet.create({
  root: {
    position: "relative",
  },
  playingTeam: {
    position: "absolute",
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: 7,
    paddingLeft: 4,
    paddingRight: 4,
  },
  topPlayingTeam: {
    top: -10,
  },
  topIncorrectPlayingTeam: {
    top: -20,
  },
  bottomPlayingTeam: {
    bottom: 1,
  },
  teamSeed: {
    color: SECONDARY_DEFAULT_COLOR,
  },
})

const Matchup: FC<IMatchupProps> = ({ matchup, pickUtils, baseHeight, baseWidth, mirrored }) => {
  if (!(pickUtils && pickUtils.bracketMapping && pickUtils.bracketUtils && pickUtils.bracketPeriodTree)) {
    return null
  }
  const { bracketMapping, bracketUtils, bracketPeriodTree } = pickUtils
  const { rounds } = bracketPeriodTree
  const {
    picksSlotAndPosItemId,
    realSlotAndPosItemId,
    interactiveStatus,
    itemById,
    seedByItemId,
    eliminatedItemIds,
    itemIdPlayinItemNameOverrides,
  } = bracketMapping
  const { tournamentRound, isPlayin } = matchup

  const useStatic = isPlayin
  const src = useStatic ? realSlotAndPosItemId : picksSlotAndPosItemId

  const isLocked = useStatic || interactiveStatus === "locked"
  const isFirstRound = rounds.indexOf(tournamentRound) === 0

  const renderMatchup = (painter, availableWidth, availableHeight) => {
    if (mirrored) {
      painter.moveTo(availableWidth, 0).lineTo(0, 0).lineTo(0, availableHeight).lineTo(availableWidth, availableHeight)
    } else {
      painter.moveTo(0, 0).lineTo(availableWidth, 0).lineTo(availableWidth, availableHeight).lineTo(0, availableHeight)
    }
    painter.stroke()
    return null
  }

  return (
    <View style={styles}>
      <Canvas paint={renderMatchup} style={{ width: baseWidth, height: baseHeight }} />
      {bracketUtils.teamPositions.map((position) => {
        const key = `${matchup.id}-${position}`
        const itemId = src[key]
        const item = itemById[itemId] || {}
        const seed = seedByItemId[itemId]
        const playinItemNameOverride = itemIdPlayinItemNameOverrides[itemId]
        const correctItemId: string | null = (isLocked && realSlotAndPosItemId[key]) || null
        const showAsCorrect = !isFirstRound && correctItemId === itemId
        const isEliminated = isLocked && !!eliminatedItemIds[itemId]
        const showAsIncorrect = !isFirstRound && !useStatic && !showAsCorrect && (isEliminated || (correctItemId && correctItemId !== itemId))
        const correctItemIfIncorrect = (showAsIncorrect && correctItemId && itemById[correctItemId]) || null

        return (
          <View
            key={key}
            style={[
              styles.playingTeam,
              position === "top" ? (!!correctItemIfIncorrect ? styles.topIncorrectPlayingTeam : styles.topPlayingTeam) : styles.bottomPlayingTeam,
            ]}
          >
            {!!correctItemIfIncorrect && (
              <Text style={{ textAlign: mirrored ? "right" : "left" }}>
                {correctItemIfIncorrect.mediumName && correctItemIfIncorrect.mediumName.length <= 11
                  ? correctItemIfIncorrect.mediumName
                  : displayTeamAbbrev(correctItemIfIncorrect.abbrev) ?? ""}
              </Text>
            )}
            <>
              <Text
                style={{
                  textAlign: mirrored ? "right" : "left",
                  color: showAsCorrect ? SELECTION_CORRECT_COLOR : showAsIncorrect ? SELECTION_INCORRECT_COLOR : DEFAULT_COLOR,
                  textDecoration: showAsIncorrect ? "line-through" : "none",
                }}
              >
                {isFirstRound && !mirrored && <Text style={styles.teamSeed}>{`${seed}  `}</Text>}
                {playinItemNameOverride || (item.mediumName && item.mediumName.length <= 11 ? item.mediumName : displayTeamAbbrev(item.abbrev) ?? "")}
                {isFirstRound && mirrored && <Text style={styles.teamSeed}>{`  ${seed}`}</Text>}
              </Text>
            </>
          </View>
        )
      })}
    </View>
  )
}

export default Matchup
