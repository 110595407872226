import React, { FC } from "react"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import { RoundDescription, Variant, DEFAULT_FONT_FAMILY, TOURNAMENT_ROUNDS, BASE_MATCHUP_WIDTH } from "./PrintableTypes"

interface IRoundDateProps {
  roundDescription: RoundDescription
}

const RoundDescriptionComponentStyles = StyleSheet.create({
  root: {
    width: "100%",
    height: 16,
  },
  roundName: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 700,
    fontSize: 7,
    textAlign: "center",
  },
  roundDate: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: 6,
    textAlign: "center",
  },
})

const RoundDescriptionComponent: FC<IRoundDateProps> = ({ roundDescription: { roundDate, roundName } }) => {
  return (
    <View style={RoundDescriptionComponentStyles.root}>
      <Text style={RoundDescriptionComponentStyles.roundName}>{roundName}</Text>
      <Text style={RoundDescriptionComponentStyles.roundDate}>{roundDate}</Text>
    </View>
  )
}

const roundDatesStyles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  finalRound: {
    flex: 1,
  },
})

interface IRoundDescriptionRowProps {
  variant: Variant
}

const RoundDescriptionRow: FC<IRoundDescriptionRowProps> = ({ variant }) => {
  const tournamentRounds = TOURNAMENT_ROUNDS[variant]
  const rounds = tournamentRounds.slice(0, -1)
  const finalRound = tournamentRounds[tournamentRounds.length - 1]
  const baseWidth = BASE_MATCHUP_WIDTH[variant]

  return (
    <View style={roundDatesStyles.root}>
      {rounds.map((r) => (
        <View style={{ width: baseWidth }} key={r.roundName}>
          <RoundDescriptionComponent roundDescription={r} />
        </View>
      ))}
      <View style={roundDatesStyles.finalRound}>
        <RoundDescriptionComponent roundDescription={finalRound} />
      </View>
      {rounds.reverse().map((r) => (
        <View style={{ width: baseWidth }} key={r.roundName}>
          <RoundDescriptionComponent roundDescription={r} />
        </View>
      ))}
    </View>
  )
}

export default RoundDescriptionRow
