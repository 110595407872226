import React, { FC } from "react"
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer"
import {
  IFinalMatchupProps,
  FINAL_MATCHUP_DIMENSIONS,
  FINAL_MATCHUP_CHAMPION_DIMENSIONS,
  FINAL_MATCHUP_TEAMS_DIMENSIONS,
  FINAL_MATCHUP_BACKGROUND,
  SELECTION_CORRECT_COLOR,
  SELECTION_INCORRECT_COLOR,
  DEFAULT_COLOR,
  DEFAULT_FONT_FAMILY,
} from "./PrintableTypes"

const styles = StyleSheet.create({
  root: {
    width: FINAL_MATCHUP_DIMENSIONS[0],
    height: FINAL_MATCHUP_DIMENSIONS[1],
    position: "relative",
  },
  box: {
    backgroundColor: FINAL_MATCHUP_BACKGROUND,
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 6,
    position: "absolute",
  },
  teams: {
    width: FINAL_MATCHUP_TEAMS_DIMENSIONS[0],
    height: FINAL_MATCHUP_TEAMS_DIMENSIONS[1],
    top: "50%",
    transform: `translateY(-${FINAL_MATCHUP_TEAMS_DIMENSIONS[1] / 2})`,
  },
  champion: {
    width: FINAL_MATCHUP_CHAMPION_DIMENSIONS[0],
    height: FINAL_MATCHUP_CHAMPION_DIMENSIONS[1],
    left: "50%",
    transform: `translateX(-${FINAL_MATCHUP_CHAMPION_DIMENSIONS[0] / 2})`,
  },
  playingTeam: {
    position: "absolute",
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: 10,
    paddingLeft: 4,
    paddingRight: 4,
    bottom: 2,
    display: "flex",
    flexDirection: "column",
  },
  topPlayingTeam: {
    left: 16,
    alignItems: "flex-start",
  },
  bottomPlayingTeam: {
    right: 16,
    alignItems: "flex-end",
  },
  absoluteContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  championTeam: {
    position: "absolute",
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: 11,
    bottom: 2,
    width: "100%",
    textAlign: "center",
  },
  championTeamLogo: {
    height: 32,
    marginTop: 6,
    marginLeft: "auto",
    marginRight: "auto",
  },
  championTeamLogoIncorrect: {
    height: 16,
    marginTop: 4,
    marginLeft: "auto",
    marginRight: "auto",
  },
})

const FinalMatchup: FC<IFinalMatchupProps> = ({ matchup, pickUtils }) => {
  if (!(pickUtils && pickUtils.bracketMapping && pickUtils.bracketUtils && pickUtils.bracketPeriodTree)) {
    return null
  }

  const { bracketMapping, bracketUtils } = pickUtils
  const {
    picksSlotAndPosItemId,
    realSlotAndPosItemId,
    picks,
    interactiveStatus,
    itemById,
    eliminatedItemIds,
    itemIdPlayinItemNameOverrides,
  } = bracketMapping
  const { id: matchupId, isPlayin } = matchup

  const useStatic = isPlayin
  const src = useStatic ? realSlotAndPosItemId : picksSlotAndPosItemId
  const isLocked = useStatic || interactiveStatus === "locked"
  const pickedItemId = (!useStatic && picks[matchupId]) || null
  const realChampItemId = (isLocked && matchup.winnerId) || null
  const pickedChampItem = pickedItemId || null
  const champItemIdToDisplay = (useStatic && realChampItemId) || pickedChampItem
  const champItemToDisplay = champItemIdToDisplay && itemById[champItemIdToDisplay]
  const championItemNameOverride = champItemIdToDisplay ? itemIdPlayinItemNameOverrides[champItemIdToDisplay] : null
  const isEliminatedChamp = isLocked && pickedChampItem && !!eliminatedItemIds[pickedChampItem]
  const showAsCorrectChamp = isLocked && !!pickedChampItem && pickedChampItem === realChampItemId
  const showAsIncorrectChamp = !useStatic && !!(isEliminatedChamp || (realChampItemId && pickedChampItem && realChampItemId !== pickedChampItem))
  const correctChampIfIncorrect = (showAsIncorrectChamp && realChampItemId && itemById[realChampItemId]) || null

  return (
    <View style={styles.root}>
      <View style={[styles.teams, styles.box]}>
        {bracketUtils.teamPositions.map((position) => {
          const key = `${matchup.id}-${position}`
          const itemId = src[key]
          const item = itemById[itemId] || {}
          const playinItemNameOverride = itemIdPlayinItemNameOverrides[itemId]
          const correctItemId: string | null = (isLocked && realSlotAndPosItemId[key]) || null
          const showAsCorrect = correctItemId === itemId
          const isEliminated = isLocked && !!eliminatedItemIds[itemId]
          const showAsIncorrect = !useStatic && !showAsCorrect && (isEliminated || (correctItemId && correctItemId !== itemId))
          const correctItemIfIncorrect = (showAsIncorrect && correctItemId && itemById[correctItemId]) || null

          return (
            <View key={key} style={[styles.playingTeam, position === "top" ? styles.topPlayingTeam : styles.bottomPlayingTeam]}>
              {!!correctItemIfIncorrect && (
                <Text>
                  {correctItemIfIncorrect.mediumName && correctItemIfIncorrect.mediumName.length <= 11
                    ? correctItemIfIncorrect.mediumName
                    : correctItemIfIncorrect.abbrev}
                </Text>
              )}
              <Text
                style={{
                  color: showAsCorrect ? SELECTION_CORRECT_COLOR : showAsIncorrect ? SELECTION_INCORRECT_COLOR : DEFAULT_COLOR,
                  textDecoration: showAsIncorrect ? "line-through" : "none",
                }}
              >
                {playinItemNameOverride || item.mediumName || item.abbrev}
              </Text>
            </View>
          )
        })}
      </View>
      <View style={[styles.champion, styles.box]}>
        {champItemToDisplay && (
          <View style={styles.absoluteContainer}>
            {!!correctChampIfIncorrect && (
              <Text
                style={[
                  styles.championTeam,
                  {
                    color: DEFAULT_COLOR,
                    fontSize: 14,
                    bottom: 16,
                  },
                ]}
              >
                {correctChampIfIncorrect.mediumName || correctChampIfIncorrect.abbrev}
              </Text>
            )}
            {!championItemNameOverride && (
              <Image
                style={!!correctChampIfIncorrect ? styles.championTeamLogoIncorrect : styles.championTeamLogo}
                src={`https://sports.cbsimg.net/images/collegebasketball/logos/100x100/${champItemToDisplay.abbrev}.png`}
              />
            )}

            <Text
              style={[
                styles.championTeam,
                {
                  color: showAsCorrectChamp ? SELECTION_CORRECT_COLOR : showAsIncorrectChamp ? SELECTION_INCORRECT_COLOR : DEFAULT_COLOR,
                  textDecoration: showAsIncorrectChamp ? "line-through" : "none",
                },
              ]}
            >
              {championItemNameOverride || champItemToDisplay.mediumName || champItemToDisplay.abbrev}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}

export default FinalMatchup
