import styled from "styled-components/macro"
import { pxToRem, palette, fontWeight, layout } from "../../../utils/style-utils"

const modalMaxW = layout.dialogMaxWidth
const modalInnerMaxW = 350
export const GameGateModalStyles = styled.div`
  & {
    width: 95vw;
    max-width: ${pxToRem(modalMaxW)};
    background: ${palette.white};
    box-shadow: 0px ${pxToRem(4)} ${pxToRem(23)} rgba(0, 0, 0, 0.15);
    border-radius: ${pxToRem(4)};
    padding: ${pxToRem(32)} 0 ${pxToRem(16)};
    color: ${palette.neutral70};
    /* display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center; */
  }
  & > *:not(.full-width--true) {
    margin: 0 auto;
    width: calc(100% - ${pxToRem(16)});
    max-width: ${pxToRem(modalInnerMaxW)};
  }
  & p,
  section,
  header,
  footer {
    padding-bottom: ${pxToRem(16)};
    line-height: 1.4em;
  }
  & .title {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(18)};
    padding-left: 0;
    padding-right: 0;
    letter-spacing: normal;
    text-transform: initial;
    text-align: center;
  }
  & .hr {
    display: none;
  }
  & .logo {
    min-height: ${pxToRem(100 + 16)};
    padding: 0 0 ${pxToRem(16)} 0;
    & svg {
      max-height: 100%;
      height: ${pxToRem(100)};
    }
  }
  & .p {
    text-align: center;
  }
  & .fm {
    display: block;
    & > * {
      margin: 0 0 ${pxToRem(16)};
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    & button,
    a {
      max-width: 100%;
      width: 100%;
    }
  }
  & small {
    border-top: 1px solid ${palette.gray3};
    color: ${palette.gray2};
    font-size: 0.6em;
    padding: ${pxToRem(20)} ${pxToRem(24)} ${pxToRem(4)};
  }
`
