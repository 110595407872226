import React, { FC } from "react"
import { View, StyleSheet } from "@react-pdf/renderer"
import { ISectionProps } from "./PrintableTypes"
import TopSubSection from "./TopSubSection"
import BottomSubSection from "./BottomSubSection"

const styles = StyleSheet.create({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  subSection: {
    flex: 1,
  },
})

const RightSection: FC<ISectionProps> = ({ pickUtils, variant }) => {
  const matchups = pickUtils.bracketPeriodTree?.right
  return (
    <View style={styles.root}>
      <View style={styles.subSection}>
        <TopSubSection pickUtils={pickUtils} matchups={matchups} variant={variant} mirrored />
      </View>
      <View style={styles.subSection}>
        <BottomSubSection pickUtils={pickUtils} matchups={matchups} variant={variant} mirrored />
      </View>
    </View>
  )
}

export default RightSection
