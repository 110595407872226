import React, { FC } from "react"
import { View, StyleSheet } from "@react-pdf/renderer"
import { IRegionRoundProps } from "./PrintableTypes"
import Matchup from "./Matchup"

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    alignItems: "center",
  },
})

const RegionRound: FC<IRegionRoundProps> = ({ matchups, pickUtils, mirrored, baseHeight, baseWidth }) => {
  const roundMatchups = matchups?.map((matchup, index) => {
    return <Matchup key={index} pickUtils={pickUtils} matchup={matchup} baseHeight={baseHeight} baseWidth={baseWidth} mirrored={mirrored} />
  })
  return <View style={styles.root}>{roundMatchups}</View>
}

export default RegionRound
