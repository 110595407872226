import styled from "styled-components/macro"
import { pxToRem } from "../../../utils/style-utils"

export const NativeAppPromoWrapper = styled.div`
  & > * {
    margin-bottom: ${pxToRem(28)};
    text-align: center;
    line-height: 1.4em;
  }
  & .app-logo {
    display: block;
  }
  & .app-logo img {
    border-radius: 20%;
    height: ${pxToRem(175)};
  }
  & .alct {
    display: flex;
    justify-content: center;
  }
  & .app-links > * {
    margin: 0;
  }
  & .app-links > * {
    margin: 0;
    display: flex;
    flex-flow: row;
  }
  & .app-links a {
    margin: ${pxToRem(6)};
  }
`
