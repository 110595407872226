import React, { FC } from "react"
import { View, Image } from "@react-pdf/renderer"
import { Sponsor } from "./PrintableTypes"

const RESOURSE_MAP: Record<Sponsor, string> = {
  NISSAN: `/static/printable-assets/nissan_banner.png`,
  ATT: "/static/printable-assets/att_banner.png",
}

interface IBannerProps {
  variant: Sponsor
}

const Banner: FC<IBannerProps> = ({ variant }) => {
  return (
    <View>
      <Image src={RESOURSE_MAP[variant]} />
    </View>
  )
}

export default Banner
