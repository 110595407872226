/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { Redirect } from "react-router-dom"
import { useDeviceType } from "../../../Base/DeviceType"
import { withoutDomain } from "../../../utils/url-utils"
import { IBracketProps } from "../containers/IPicksProps"
import SingleBracket from "./SingleBracket"

const Bracket = (props: IBracketProps) => {
  const deviceType = useDeviceType()
  const { pickUtils, gameInstanceUid } = props

  const bracketMapping = pickUtils.bracketMapping
  const bracketPeriodTree = pickUtils.bracketPeriodTree
  if (!bracketMapping || !bracketPeriodTree) {
    return null
  }

  if (typeof window !== "undefined" && window.hasOwnProperty("QQ")) {
    window.pickUtils = pickUtils
  }

  if (deviceType === "handheld") {
    return <Redirect to={withoutDomain(`/games/roadblock?showCongrats=false&gameType=${gameInstanceUid}`)} />
  }
  return <SingleBracket {...props} />
}
export default Bracket
