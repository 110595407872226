import styled from "styled-components/macro"
import { colorFor } from "../../../../common/theme-helpers"
import { ButtonBase } from "../../../components/Button"
import { FillScreen, FlexCols } from "../../../components/FlexComponents"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const OnboardingFillScreen = styled(FillScreen)`
  justify-content: flex-start;
  color: ${colorFor};
  &.is-modal--true {
    width: ${pxToRem(700)};
    max-width: 100%;
    min-height: 60vh;
    border-radius: ${pxToRem(7)};
    background-size: cover;
    position: relative;
    background-color: ${palette.white};
  }
  &.is-modal--true.is-auth--true {
    min-height: unset;
    padding-top: ${pxToRem(16)};
    padding-bottom: ${pxToRem(16)};
  }
`
export const LogoStyled = styled.div`
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(350)};
  & svg,
  img {
    max-height: ${pxToRem(200)};
  }
  @media (max-height: ${pxToRem(750)}) {
    padding: ${pxToRem(16)};
    .hide-mobile-nav--false & {
      min-height: 30vh;
    }
  }
  .is-modal--true & {
    padding: ${pxToRem(16)};
    min-height: unset;
  }
`

export const ComingSoonAppLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  & > picture img {
    height: ${pxToRem(110)};
    border-radius: ${pxToRem(25)};
  }
  @media (max-width: ${pxToRem(950)}) {
    & > picture img {
      height: ${pxToRem(90)};
    }
  }
`
export const ComingSoonAppRow = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 5%;
  & a {
    display: block;
    padding: ${pxToRem(3)} 0;
  }
`
export const AuthGateViewCtas = styled(FlexCols)`
  flex: 1 0 auto;
`

export const AuthGateViewText = styled.p`
  width: 100%;
  text-align: center;
  padding: 0 ${pxToRem(16)};
  font-size: ${pxToRem(20)};
  margin-bottom: ${pxToRem(22)};
  font-weight: ${fontWeight.semiBold};
  line-height: 1.4em;
  & strong {
    color: ${palette.black};
    font-weight: ${fontWeight.bold};
  }
`
export const AuthGateViewHr = styled.hr`
  height: ${pxToRem(3)};
  width: ${pxToRem(32)};
  margin: 0 auto ${pxToRem(22)};
  background-color: currentColor;
`

export const AuthGateViewCta = styled(ButtonBase)`
  margin: ${pxToRem(8)} ${pxToRem(16)};
`
