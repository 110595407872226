import styled from "styled-components/macro"
import { fontFamily, fontSizes, fontWeight, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

export const BracketToolbarWarp = styled.div<{ maxWidth: number | undefined; minWidth: number | undefined }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `min(${pxToRem(maxWidth)}, 100vw)` : "none")};
  margin: 0 auto var(--grid-gap);
  height: 5rem;
  box-sizing: border-box;
  border: 1px solid ${palette.gray90};
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: ${palette.white};

  & > .toolbar-left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    min-width: 0;
    flex: 1;

    & > .toolbar-entry-logo {
      display: flex;
      position: relative;
      vertical-align: middle;
      border-radius: 50%;
      overflow: hidden;
      height: 3rem;
      width: 3rem;
      margin-right: 1rem;

      img {
        height: auto;
        max-width: 100%;
        flex: 1;
        opacity: 1;
        transition: opacity 0.5s ease-in 0.5s;
        vertical-align: top;
      }
    }

    & > .toolbar-entry-pool-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-width: 0;
      flex: 1;

      & > .toolbar-entry-pool-information-top {
        display: flex;
        flex-direction: row;
        max-width: 100%;

        .entry-name {
          font-family: ${fontFamily.base};
          font-size: ${pxToRem(18)};
          font-style: normal;
          font-weight: ${fontWeight.bold};
          line-height: 1.5rem;
          letter-spacing: -0.1px;
          text-align: left;
          margin-right: 0.5rem;
          color: ${palette.gray20};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }
      }

      & > .pool-name {
        font-family: ${fontFamily.base};
        font-size: ${fontSizes.regular};
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${palette.gray40};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }
  & > .toolbar-right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const BtnLabel = styled.span`
  font-size: ${fontSizes.regular};
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  font-weight: ${fontWeight.semiBold};
`
export const Separator = styled.div`
  width: 1px;
  background-color: ${palette.gray90};
  height: 2.25rem;
  margin: 0 0.5rem;
`
export const EditAvatarMenuWrap = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input.input-file {
    display: none;
  }
`
