import React, { FC } from "react"
import { View, StyleSheet } from "@react-pdf/renderer"
import { IHavePickUtils, Variant, getHeightForRound, FINAL_MATCHUP_DIMENSIONS, BASE_MATCHUP_HEIGHT, BASE_MATCHUP_WIDTH } from "./PrintableTypes"
import LeftSection from "./LeftSection"
import RightSection from "./RightSection"
import Matchup from "./Matchup"
import FinalMatchup from "./FinalMatchup"
import RoundDescriptionRow from "./RoundDescriptionRow"

interface IBracketProps extends IHavePickUtils {
  variant: Variant
}

const styles = StyleSheet.create({
  root: {
    height: "100%",
  },
  bracketRoot: {
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    height: 502,
  },
  section: {
    flex: 1,
    maxHeight: 470,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dateBox: {
    marginBottom: 16,
    marginTop: 8,
  },
  final: {
    position: "absolute",
    top: 275,
    left: "50%",
    transform: `translate(-${FINAL_MATCHUP_DIMENSIONS[0] / 2}, -${FINAL_MATCHUP_DIMENSIONS[1] / 2})`,
  },
})

const Bracket: FC<IBracketProps> = ({ pickUtils, variant }) => {
  const midMatchups = pickUtils.bracketPeriodTree?.mid ?? []
  const [baseHeight] = getHeightForRound(variant === "SWEET16" ? 2 : 4, BASE_MATCHUP_HEIGHT[variant]) //This will compute the base height of the last matchup, connecting top and bottom regions
  const baseWidth = BASE_MATCHUP_WIDTH[variant]

  return (
    <View style={styles.root}>
      <View style={styles.dateBox}>
        <RoundDescriptionRow variant={variant} />
      </View>
      <View style={styles.bracketRoot}>
        <View style={styles.section}>
          <LeftSection pickUtils={pickUtils} variant={variant} />
          <Matchup baseHeight={baseHeight} baseWidth={baseWidth} matchup={midMatchups[0]?.[0]} pickUtils={pickUtils} />
        </View>
        <View style={[styles.section, { justifyContent: "flex-end" }]}>
          <Matchup baseHeight={baseHeight} baseWidth={baseWidth} matchup={midMatchups[0]?.[1]} pickUtils={pickUtils} mirrored />
          <RightSection pickUtils={pickUtils} variant={variant} />
        </View>
      </View>
      <View style={styles.final}>
        <FinalMatchup pickUtils={pickUtils} matchup={midMatchups[1]?.[0]} />
      </View>
    </View>
  )
}

export default Bracket
