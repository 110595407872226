import React, { FC } from "react"
import GenericConfirmationModal, { IGenericConfirmationModalProps } from "./GenericConfirmationModal"

const CTA_BUTTON_LABEL = "Submit"

type IncompleteBracketConfirmationModalPropsType = Pick<
  IGenericConfirmationModalProps,
  "isOpen" | "onClose" | "onConfirm" | "title" | "message" | "selectorLabel"
>

const IncompleteBracketConfirmationModal: FC<IncompleteBracketConfirmationModalPropsType> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  selectorLabel,
}) => {
  return (
    <GenericConfirmationModal
      isOpen={isOpen}
      title={title}
      message={message}
      ctaButtonLabel={CTA_BUTTON_LABEL}
      onClose={onClose}
      onConfirm={onConfirm}
      selectorLabel={selectorLabel}
    />
  )
}

export default IncompleteBracketConfirmationModal
