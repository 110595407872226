import * as React from "react"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { FlexRow } from "../../../components/FlexComponents"
import Modal, { ModalWrapper } from "../../../components/Modal"
import { AppLink, AppLinks, AppLogo } from "../../NonPoolPages/components/NativeAppPromos"
import { NativeAppPromoWrapper } from "../styles/NativeAppPromo.styles"

class NativeAppPromoModal extends React.PureComponent<{
  isOpen: boolean
  close: any
  afterClose: any
  afterOpen: any
  gameInstanceUid: string
  season: any
}> {
  public static modalKey = "NativeAppPromoModal"

  public render() {
    const { isOpen, close, afterClose, afterOpen, gameInstanceUid } = this.props
    const season = this.props.season || ({} as never)
    const ua = (typeof navigator !== "undefined" && navigator.userAgent) || ""
    const isIosOrAndroid = /android|iphone|ipad/i.test(ua)
    const text = isIosOrAndroid ? "Get the App" : "Download on Your Phone"
    return (
      <Modal modalType="dialog" isOpen={isOpen} onBackgroundClick={close} afterClose={afterClose} afterOpen={afterOpen}>
        <AnalyticScreen subfeature={`native app promo`} title={`Native App Promo`} isModal={true} />
        <ModalWrapper header="Get the CBS Sports App" closeBtnAction={close}>
          <NativeAppPromoWrapper>
            {/*<div>
                <AppLogo gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} className="app-logo" />
                <img src="https://lh3.googleusercontent.com/Oi1xb3v6970s-0wB4UK73Q2DqjDUX7_VSbuaX2YSnXgA-54GA53qbCJ9lJC3IxsAJvUV=w1440-h620-rw" />
              </div>*/}
            <AppLogo gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} className="app-logo" />
            <p>Easily access your picks and standings plus get the latest scores, stats, news and alerts on the go.</p>
            <AppLinks className="app-links" />
            <FlexRow>
              <AppLink gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} children={text} />
            </FlexRow>
          </NativeAppPromoWrapper>
        </ModalWrapper>
      </Modal>
    )
  }
}

export default NativeAppPromoModal
