import styled from "styled-components/macro"
import Link from "./Link"

export const StyledOpacityLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  will-change: opacity;
  display: block;
  @media (hover: hover) {
    &:hover:not(:active) {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.7;
  }
`
